import React from 'react'
import 'twin.macro'

import { navigate } from 'gatsby'
import tw, { TwStyle } from 'twin.macro'

import fireEvent from 'context/tracking/events'
import {
  ContentModule,
  ContentSlot,
  getParagraph,
} from 'mx/components/new/utils'
import { WebsiteButton as Button } from 'mx/components/section-components'

interface MeasurementsProps {
  classname?: TwStyle
  data: ContentModule
}
export const Measurements = ({ classname, data }: MeasurementsProps) => {
  const { contentSlots, moduleTitle } = data
  if (!contentSlots) return null
  return (
    <div
      tw="flex flex-col items-center justify-center gap-7 mx-auto px-11 md:px-12 xl:px-0 w-full max-w-6xl"
      css={[classname]}
    >
      <div tw="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
        <div tw="w-full max-w-xs md:max-w-xl space-y-4 md:space-y-8 md:w-auto">
          <h1 tw="text-4xl md:text-5xl font-light text-gray-dark text-balance">
            {moduleTitle}
          </h1>
        </div>
      </div>

      <div tw="w-full flex flex-col gap-16 md:gap-28">
        {contentSlots?.map((slot: ContentSlot, index: number) => (
          <div
            key={index}
            tw="pb-6 space-y-2 md:space-y-6 flex flex-col-reverse md:flex-row border-t border-stone-300"
          >
            <div tw="flex flex-col items-start gap-8 pt-2 md:pt-6 max-w-xl">
              <h2 tw="text-2xl font-light text-gray-dark text-balance">
                {slot.slotTitle}
                {!!slot.description && (
                  <>
                    <br />
                    {slot.description}
                  </>
                )}
              </h2>
              <div>
                {getParagraph(
                  slot.markdown,
                  tw`text-gray-dark text-lg font-light`,
                )}
              </div>
              {!!slot.actionText && !!slot.actionUrl && (
                <Button
                  color="transparentBlack"
                  onClick={() => {
                    fireEvent({
                      type: 'cta_clicked',
                      ctaStr: slot.actionText,
                    })
                    navigate(slot.actionUrl)
                  }}
                  rounded
                >
                  {slot.actionText}
                </Button>
              )}
            </div>
            {!!slot.photos && (
              <div tw="flex justify-start md:justify-end items-center w-full max-md:py-6 !mb-auto">
                <img
                  tw="h-12 md:h-16 w-auto"
                  src={slot.photos[0].file?.url}
                  title={slot.photos[0].title}
                  alt={slot.photos[0].description}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
